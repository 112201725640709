/* global config, moment, Promise */

'use strict';

angular.module('managerApp').controller('SlidersController', (
        $scope, $mdDialog, toast, help, procedureTools) => {

    $scope.config = config;
    $scope.medias = [];

    $scope.showAddEditPopup = () => {
        $mdDialog.show({
            templateUrl: 'app/help/sliders/item.html',
            clickOutsideToClose: false,
            locals: {
            },
            controller: ($scope, $mdDialog, toast) => {
                $scope.item = {
                    active: true
                };
                $scope.onFileSelect = (files, target) => {
                    let file = files[0];
                    if (!file) {
                        return;
                    }
                    let img = document.getElementById(target);
                    img.onload = () => {
                        $scope.$apply(function () {
                            $scope.item[
                                    target === 'preview1' ? 'picture1' : 'picture2'
                            ] = files[0];
                        });
                    };
                    let reader = new FileReader();
                    reader.onload = (function (_img) {
                        return function (e) {
                            _img.src = e.target.result;
                        };
                    })(img);
                    reader.readAsDataURL(file);
                };

                $scope.closeDialog = (validated = false, form = null) => {
                    if (!validated) {
                        $mdDialog.cancel();
                    } else {
                        if (!form.$valid) {
                            return;
                        }
                        let promise, item = $scope.item;
                        if (item.type === 'image') {
                            let formData = new FormData();
                            formData.append('type', 'image');
                            formData.append('active', item.active);
                            formData.append('default', item.picture1);
                            formData.append('mobile', item.picture2);
                            formData.append('order', item.order);
                            formData.append('SliderId', item.SliderId);
                            promise = help.addSlide(formData);
                        } else {
                            promise = help.addSlide($scope.item, 'application/json');
                        }
                        $mdDialog.hide(promise);
                    }//
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('Le slide a bien été ajouté.', 'success');
                getSliders();
            }
        }).catch((err) => {
                toast.show(err, 'error', true);
        });
    };

    $scope.showDeleteConfirm = (item) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer ce message ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                itemId: item._id
            },
            controller: ($scope, $mdDialog, toast, help, itemId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        help.delete(itemId).then(() => {
                            toast.show('Le slider a bien été supprimé.', 'success');
                            getSliders();
                        }).catch((err) => {
                            toast.show('Une erreur est survenu lors de la suppression de ce slider.', 'error');
                        });
                    }//
                };
            }
        });
    };

    $scope.toggleActive = (item) => {
        item.active = !item.active;
        help.update(item, item._id).then(() => {
            toast.show('Le slide a bien été mis à jour.', 'success');
        }).catch(() => {
            toast.show('Le slide n\'a pas pu être mis à jour.', 'error');
            item.active = !item.active;
        });
    };

    $scope.getSliders = () => {
        getSliders();
    };

    function getSliders() {
        let type = null, filter = false;
        if ($scope.type) {
            filter = true;
            type = $scope.type;
        }
        help.get('sliders', type, filter).then((data) => {
            $scope.items = data;
            for (let i = 0; i < $scope.items.length; i++) {
                let item = $scope.items[i];
                item.path = setURL(item.path);
                item.mobilePath = setURL(item.mobilePath);
                item.procedureType = item.SliderId === 'home' ? 'Accueil' :
                        procedureTools.getProcedureName(item.SliderId);
                $scope.$apply();
            }
            $scope.$apply();
        }).catch((err) => {
            toast(err, 'error');
        });
    }

    function setURL(path) {
        return path.substr(0, 4) === 'http' ? path : config.websiteURL + '/' + path;
    }

    getSliders();

});
